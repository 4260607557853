<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
				</p>
				<div class="h-b"><el-button type="text" size="small" @click="saveData" v-right-code="'LogisticsOrganization:Edit'">保存</el-button></div>
			</div>
		</div>
		<div class="form-list">
			<el-form ref="_dataInfoForm" :model="dataSource" :rules="dataCheckRule">
				<el-form-item>
					<el-col :span="3" class="form-title">所属组织机构：</el-col>
					<el-col :span="7">
						<LookPopup v-model="dataSource.BelongOrgName"
								dataTitle="组织机构"
								dataUrl='omsapi/organization/pageList'
								:dataOptions="{OrgBizType:1}"
								:custShowQueryOptions="false"
								syncField='Name'
								@syncFieldEvent="syncBelongOrgName"
							></LookPopup>
					</el-col>
					<el-col :span="3" class="form-title">上级物流组织编码：</el-col>
					<el-col :span="7">
                        <el-form-item prop="ParentCode">
                            <LookPopup v-model="dataSource.ParentCode"
								dataTitle="物流组织"
								dataUrl='omsapi/organization/getloglist'
								:dataOptions="{}"
								:custShowQueryOptions="false"
								syncField='Code'
								@syncFieldEvent="syncParentCode"
							></LookPopup>
                        </el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title">
						<span style="color:red;">*</span>
						组织机构编码：
					</el-col>
					<el-col :span="7">
						<el-form-item prop="Code">
							<el-input v-model.trim="dataSource.Code" :maxlength="30" :minlength="4" :disabled="true" placeholder=""></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title">
						<span style="color:red;">*</span>
						组织机构名称：
					</el-col>
					<el-col :span="7">
						<el-form-item prop="Name">
							<el-input v-model.trim="dataSource.Name" :maxlength="50" placeholder=""></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>

				<el-form-item>
					<el-col :span="3" class="form-title">联系人：</el-col>
					<el-col :span="7">
						<el-form-item prop="Contact">
							<el-input v-model.trim="dataSource.Contact" :maxlength="50" placeholder=""></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title">联系电话：</el-col>
					<el-col :span="7">
						<el-form-item prop="ContactPhone">
							<el-input v-model.trim="dataSource.ContactPhone" :maxlength="50" placeholder=""></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>

				<el-form-item>
					<el-col :span="3" class="form-title">邮箱：</el-col>
					<el-col :span="7">
						<el-form-item prop="Contact">
							<el-input v-model.trim="dataSource.ContactEmail" :maxlength="50" placeholder=""></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title">所在地区：</el-col>
					<el-col :span="7">
						<el-form-item>
							<Distpicker :placeholders="dataSource"></Distpicker>
						</el-form-item>
					</el-col>
				</el-form-item>

				<el-form-item>
					<el-col :span="3" class="form-title">详细地址：</el-col>
					<el-col :span="17">
						<el-form-item prop="Contact">
							<el-input v-model.trim="dataSource.Address" :maxlength="100" placeholder=""></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>

				<el-form-item>
					<el-col :span="3" class="form-title">机构属性：</el-col>
					<el-col :span="7">
						<el-form-item>
							仓库 <el-checkbox v-model="dataSource.WarehouseFlag"></el-checkbox>
							运输 <el-checkbox v-model="dataSource.TransportFlag"></el-checkbox>
							订单 <el-checkbox v-model="dataSource.OrderFlag"></el-checkbox>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>状态：</el-col>
					<el-col :span="7">
						<el-form-item prop="Status">
							<el-select v-model="dataSource.Status" :maxlength="200" :disabled="true">
								<el-option v-for="item in StatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>

				<el-form-item>
					<el-col :span="3" class="form-title">
						<span style="color:red;"></span>
						组织描述：
					</el-col>
					<el-col :span="17">
						<el-form-item prop="OrgDescribe">
							<el-input type="textarea" :rows="3" placeholder="" v-model="dataSource.OrgDescribe"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
			</el-form>
		</div>
        <DialogEx :options="organizationOptions" title="选择机构" @onOk="onOrganizationOk">
            <Organization ref="organization" :config="organizationConfig" @onSelectedRow="onOrganizationSelectedRow" @onDBClick="onOrganizationOk"></Organization>
        </DialogEx>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isDisabled: false,
				dataCheckRule: {
					Name: [{ required: true, message: '组织机构名称不能为空', trigger: 'blur' }],
					Status: [{ required: true, message: '状态不能为空', trigger: 'change' }],
				},
				StatusList: [{
					label: '启用',
					value: 100
				}, {
					label: '停用',
					value: 200
				}],
				classList: [{
					label: '公司',
					value: 0
				}, {
					label: '部门',
					value: 1
				}],
				typeList: [{
					label: '内部组织',
					value: 0
				}, {
					label: '外部组织',
					value: 1
				}],
				selectUsers: [],
                organizationList:[],
                organizationConfig: {
                    isDetailDisplay: true,
                },
                organizationOptions: {
                    visible: false,
                    size: 'small'
                },
                currentOrganizationSelectedRow: {}
			};
		},
		props: {
			config: {
				isDetailDisplay: false
			},
			dataSource: {}
		},
		mounted() {
			this.Event.$on('clearEditUserForm', () => this.resetForm);
		},
		watch: {
			dataSource: {
				handler(curVal, oldVal) {
					this.isDisabled = this.dataSource.Id != null;
				},
				deep: true
			}
		},
		methods: {
            //选择机构
            onOrganizationSelectedRow: function (row) {
                this.currentOrganizationSelectedRow = row;
            },
            onOrganizationOk: function (isDbClick) {
                var row = this.currentOrganizationSelectedRow;
                if (row instanceof Object && row.Id != null) {
                    this.dataSource.ParentCode = row.Code;
                    this.dataSource.ParentName = row.Name;
                }
                if (isDbClick) {
                    this.organizationOptions.visible = false;
                }
            },
			resetForm() {
				!this.isPassValidate && this.$refs['_dataInfoForm'].resetFields(); //清空表单
			},
			selectRow(row) {
				this.selectUsers = row;
			},
			//保存
			saveData() {
				var _this = this;
				_this.$refs["_dataInfoForm"].validate((valid) => {
					_this.isPassValidate = valid;
					if (valid) {
						_this.$ajax.send("omsapi/organization/createOrUpdate", "post", _this.dataSource, (data) => {
							_this.$parent.syncDataSource(data.Result);
							_this.Event.$emit(_this.config.moduleEvent.initial);
							_this.Utils.messageBox('保存成功.', 'success');
						});
					} else {
						return false;
					}
				});
			},
			syncParentCode(selectItem){
				if(selectItem){
					this.dataSource.ParentId = selectItem.Id;
					this.dataSource.ParentName = selectItem.Name;
				}
			},
			syncBelongOrgName(selectItem){
				if(selectItem){
					this.dataSource.BelongOrgId = selectItem.Id;
					this.dataSource.BelongOrgCode = selectItem.Code;
				}
			},
		},
		components: {
            "Organization": resolve => { require(['@/components/enterprise/selector/organizationselector.vue'], resolve) }
		}
	};
</script>

<style></style>